<header>
  <div class="mx-auto flex flex-wrap pb-2 pt-2 pl-2 pr-2 md:p-0.5 flex-col md:flex-row items-center justify-between">
    <a routerLink="/" class="px-4 md:px-16 md:w-64 flex flex-col items-center">
      <img src="/assets/img/logo.png" width="60" height="75" alt="spiritual healing logo">
    </a>
    <nav class="md:ml-auto flex flex-wrap items-center text-md font-bold justify-center">
      <a routerLink="/history" class="m-3 hover:text-gray-900">Geschichte</a>
      <a routerLink="/team" class="m-3 hover:text-gray-900">Über mich</a>
      <a routerLink="/prices" class="m-3 hover:text-gray-900">Preise</a>
      <a routerLink="/faq" class="m-3 hover:text-gray-900">FAQ</a>
    </nav>
  </div>
</header>
